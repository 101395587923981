'use client';

import { Box, Button, CircularProgress } from '@mui/material';
import { sendEvent } from '@repo/utils';
import Script from 'next/script';
import { useRef, useState } from 'react';
import { MetaMapElement, MetamapProps } from './types';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    MetamapVerification: new (_options: {
      clientId: string;
      flowId: string;
      identityId?: string;
      language?: 'en' | 'fr' | 'es' | 'pt';
      metadata: { instanceId: string; traceId?: string; fixedLanguage?: 'en' | 'fr' | 'es' | 'pt' };
    }) => MetaMapElement;
  }
}

/**
 * A Metamap component that handles user interactions with the MetamapVerification SDK.
 *
 * @param {string} instanceId - The instance ID.
 * @param {string} identityId - The identity ID.
 * @param {string} clientId - The client ID.
 * @param {string} flowId - The flow ID.
 * @return {JSX.Element} The component JSX.
 */
export const Metamap = ({
  submitLabel,
  instanceId,
  identityId,
  traceId,
  clientId,
  flowId,
  callback,
  submitting,
}: MetamapProps) => {
  const verification = useRef<MetaMapElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  return flowId && clientId ? (
    <>
      <Button
        data-testid="start-metamap"
        variant="contained"
        onClick={
          /* c8 ignore start */
          () => {
            verification.current?.start();
            setLoaded(false);
          }
          /* c8 ignore end */
        }
        disabled={!loaded || submitting}
      >
        {loaded && !submitting ? (
          (submitLabel ?? 'Comenzar')
        ) : (
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} />
            Cargando...
          </Box>
        )}
      </Button>
      <button
        id="metamapSubmit"
        style={{ display: 'none' }}
        onClick={(e) => {
          e.preventDefault();
          callback?.({ verificationId: 'a', identityId: 'b' });
        }}
      ></button>
      <Script
        onReady={
          /* c8 ignore start */
          () => {
            verification.current = new window.MetamapVerification({
              clientId,
              flowId,
              identityId,
              language: 'es',
              metadata: { instanceId, traceId, fixedLanguage: 'es' },
            });
            verification.current?.on('metamap:loaded', () => {
              sendEvent({
                app: 'onboarding',
                type: 'interaction',
                subtype: 'metamap',
                data: {
                  instanceId,
                  stage: 'loaded',
                },
              });
            });
            verification.current?.on('metamap:userStartedSdk', ({ detail }) => {
              setLoaded(true);
              sendEvent({
                app: 'onboarding',
                type: 'interaction',
                subtype: 'metamap',
                data: {
                  instanceId,
                  stage: 'userStartedSdk',
                  verificationId: detail.verificationId,
                  identityId: detail.identityId,
                },
              });
            });
            verification.current?.on('metamap:userFinishedSdk', ({ detail }) => {
              sendEvent({
                app: 'onboarding',
                type: 'interaction',
                subtype: 'metamap',
                data: {
                  instanceId,
                  stage: 'userFinishedSdk',
                  verificationId: detail.verificationId,
                  identityId: detail.identityId,
                },
              });
              callback?.(detail);
            });
            verification.current?.on('metamap:exitedSdk', ({ detail }) => {
              sendEvent({
                app: 'onboarding',
                type: 'interaction',
                subtype: 'metamap',
                data: {
                  instanceId,
                  stage: 'exitedSdk',
                  verificationId: detail.verificationId,
                  identityId: detail.identityId,
                },
              });
            });
            verification.current?.on('metamap:errorSdk', ({ detail }) => {
              sendEvent({
                app: 'onboarding',
                type: 'interaction',
                subtype: 'metamap',
                data: {
                  instanceId,
                  stage: 'errorSdk',
                  verificationId: detail.verificationId,
                  identityId: detail.identityId,
                },
              });
            });
            setLoaded(true);
          }
        }
        /* c8 ignore end */
        src="https://web-button.getmati.com/button.js"
      ></Script>
    </>
  ) : (
    <></>
  );
};
