'use client';
import { OnbForm } from '@/components/form';
import { sendOnbEvent } from '@/components/newRelic';
import { resetErrors } from '@/redux/errorSlice';
import { connectedField } from '@/redux/fields';
import { showToast } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Button, Stack, Typography } from '@mui/material';
import { initializeStep, mobileVerification } from '@repo/onb-api';
import { Otp } from '@repo/ui';
import { getClientCookie } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { formatter } from '../../../../../packages/utils/src/format';
import StepWrapper from './stepWrapper';

export default function MobileVerification({
  stepname: stepName,
}: {
  stepname: mobileVerification['name'];
}): JSX.Element {
  const router = useRouter();
  const dispatch = useDispatch();
  const mobilePhoneNumber = useSelector((state: RootState) => state.flowdata.variables?.mobilePhoneNumber);
  let instanceId = getClientCookie('instanceId');

  const resendOTP = () => {
    let error = false;
    if (instanceId) {
      sendOnbEvent({ type: 'interaction', subtype: 'resendOTP', data: { instanceId, mobilePhoneNumber } });
      initializeStep<mobileVerification>({
        instanceId,
        name: stepName,
      })
        .then((response) => {
          if (response.hardError || response.softError) {
            error = true;
          } else {
            dispatch(showToast({ text: 'Código reenviado', kind: 'success' }));
            dispatch(resetErrors());
          }
        })
        .catch(() => {
          error = true;
        })
        .finally(() => {
          if (error) {
            sendOnbEvent({ type: 'error', subtype: 'initStep', data: { stepName } });
            router.push('/error');
          }
        });
    }
  };

  return (
    <StepWrapper>
      <Typography variant="h1">Ingresá el código que te enviamos</Typography>
      <Stack>
        <Typography variant="h2">
          Te llegará por SMS y WhatsApp
          {mobilePhoneNumber && (
            <>
              {' al: '}
              <span style={{ fontWeight: 'bold' }}>{formatter(mobilePhoneNumber, 'phone')}</span>
            </>
          )}
        </Typography>
        <Button
          variant="text"
          size="small"
          sx={{ width: 'max-content' }}
          onClick={() => router.push('/flow/CONTACT_INFORMATION')}
        >
          Cambiar número
        </Button>
      </Stack>
      <OnbForm<mobileVerification> stepName={stepName!}>
        <Otp
          fields={4}
          resend={resendOTP}
          {...connectedField({
            stepName,
            name: 'smsOtp',
            required: true,
            errorHandler: {
              type: 'text',
              errorText: 'Ingresa un código válido',
              validate: (value) => value.length > 3,
            },
          })}
        />
      </OnbForm>
    </StepWrapper>
  );
}
