'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { Button, Typography } from '@mui/material';
import { customerRegistration } from '@repo/onb-api';
import { Doodles, RadioGroup } from '@repo/ui';
import { useRouter } from 'next/navigation';
import StepWrapper from './stepWrapper';

export default function CustomerRegistration({
  stepdata: stepData,
  stepname: stepName,
}: {
  stepdata?: customerRegistration['variables'];
  stepname?: customerRegistration['name'];
}): JSX.Element {
  const router = useRouter();
  const fullNames = stepData?.fullNames?.map((name) => ({ value: name, label: name })) || [{ value: '', label: '' }];
  const hasFullNames = fullNames.length > 0;

  return (
    <StepWrapper>
      <Typography variant="h1">Validemos tu identidad</Typography>
      <Typography variant="h2">
        {hasFullNames
          ? 'Tu DNI y género nos arrojó este resultado. ¿Sos vos? Si no, revisá y editá tu número de DNI ingresado anteriormente.'
          : 'No se encontraron datos con tu número de DNI. Por favor, revisá el número de DNI ingresado anteriormente.'}
      </Typography>
      <OnbForm<customerRegistration>
        stepName={stepName!}
        hideSubmit={!hasFullNames}
        sx={!hasFullNames ? { alignItems: 'center', pt: 4 } : {}}
      >
        {hasFullNames ? (
          <>
            <RadioGroup
              data-testid="fullName"
              aria-labelledby="fullName-label"
              options={fullNames}
              {...connectedField({
                stepName,
                name: 'fullName',
                required: true,
                errorHandler: {
                  type: 'radioGroup',
                  errorText: 'Este campo es requerido',
                },
              })}
            />
            <Button variant="text" onClick={() => router.back()}>
              {stepData?.fullNames?.length || 0 > 1 ? 'Ninguno es mi nombre' : 'No es mi nombre'}
            </Button>
          </>
        ) : (
          <Doodles type="end" />
        )}
      </OnbForm>
    </StepWrapper>
  );
}
