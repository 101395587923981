'use client';
import { ConfirmationDetails } from '@/components/confirmationDetails';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { reviewAndSubmit } from '@repo/onb-api';
import { Checkbox, COLORS, MaterialIcon, OrderCard } from '@repo/ui';
import { formatter, getClientCookie, parseJwt } from '@repo/utils';
import { useRouter } from 'next/navigation';
import StepWrapper from './stepWrapper';

export default function ReviewAndSubmit({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname: reviewAndSubmit['name'];
  stepdata?: reviewAndSubmit['variables'];
}): JSX.Element {
  const router = useRouter();
  // const variables = useSelector((state: RootState) => state.flowdata.variables);
  const {
    requestedAmount = 0,
    installmentCount = 0,
    installmentRates: { TEA: tea = 0, CFTNA: cftna = 0, TNA: tna = 0 } = {},
    installmentAmount = 0,
    installmentFirstDueDate = '2022-01-01',
    paymentMethod,
    bankAccountReference,
    cardNumber = '1234********1234',
    cardIssuer,
    termsAndConditions,
    paymentSchedule,
    documentNumber,
    gender,
    fullName,
    birthDate,
    taxpayerId,
    email,
    mobilePhoneNumber,
    province,
    withInterest,
    finalAmount = 0,
    installmentPlanType,
  } = stepData || {};

  const isCuotas = process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-cuotas';
  const subtitle = isCuotas
    ? 'Solo falta que confirmes la operación y disfrutes tu compra.'
    : 'Solo falta que confirmes tu préstamo para empezar a disfrutarlo.';

  const orderData = parseJwt(getClientCookie('tokenData') || '') as getOrderCredentialJWT;

  let merchantName, price, merchantOrderId;
  if (isCuotas) {
    merchantName = orderData.merchantName;
    price = orderData.price;
    merchantOrderId = orderData.merchantOrderId;
  }

  return (
    <StepWrapper>
      <Stack gap={1}>
        <Typography variant="h1">Estamos casi listos</Typography>
        <Typography variant="h2">{subtitle}</Typography>
      </Stack>
      {isCuotas ? (
        <>
          <OrderCard merchantName={merchantName || ''} merchantOrderId={merchantOrderId || ''} price={price || ''} />
          <Grid
            container
            direction={'column'}
            sx={{
              width: '100%',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'background.paper',
              border: '1px solid',
              borderColor: 'primary.main',
            }}
          >
            <Grid container direction={'column'} gap={1}>
              <Grid container direction={'row'} gap={1} alignItems="center" justifyContent={'space-between'}>
                <Typography variant="h2" fontSize={18} fontWeight={'500'}>
                  Tu compra en cuotas
                </Typography>
                <Tooltip title={'Editar cuotas'}>
                  <IconButton onClick={() => router.push('/flow/REPAYMENT_TERMS')}>
                    <MaterialIcon color="primary" icon="edit" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid container direction={'row'} gap={1} alignItems="center">
                <Typography
                  component={'span'}
                  variant="h1"
                  color="primary.main"
                >{`${installmentCount} cuotas`}</Typography>
                {!withInterest && (
                  <Typography variant="body2" color="primary.main">
                    Sin Interés
                  </Typography>
                )}
              </Grid>
              <Grid container direction={'row'} gap={1} alignItems="center">
                <Typography
                  component={'span'}
                  variant="h1"
                  color="primary.main"
                >{`${formatter(installmentAmount.toString(), 'currency')}`}</Typography>
                <Typography variant="body2" color="primary.main">
                  por mes
                </Typography>
              </Grid>
              <Typography variant="subtitle2">Total a pagar: {formatter(finalAmount, 'currency')}</Typography>
            </Grid>
          </Grid>
          {installmentPlanType === 'UPFRONT_PAYMENT' ? (
            <>
              <Grid
                container
                sx={{ gap: 3 }}
                direction={'row'}
                wrap="nowrap"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <MaterialIcon color="primary" containerType="circle" icon="calendar_month" />
                <Typography variant="body2" color={'secondary.main'}>
                  Pagás la primera cuota hoy
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ gap: 3 }}
                direction={'row'}
                wrap="nowrap"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <MaterialIcon color="primary" containerType="circle" icon="credit_card" />
                <Typography variant="body2" color={'secondary.main'}>
                  Pagás con tarjeta de débito
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon color="primary" containerType="circle" icon="calendar_month" />
              <Typography variant="body2" color={'secondary.main'}>
                Pagás la primera cuota el {formatter(installmentFirstDueDate, 'date')}
              </Typography>
            </Grid>
          )}
        </>
      ) : (
        <Stack
          direction="column"
          p={2}
          sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: 2, gap: 2 }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body3" width={130}>
              Monto que estás solicitando
            </Typography>
            <Typography
              component={'span'}
              variant="h1"
              color="primary.main"
            >{`${formatter(requestedAmount.toString(), 'currency')}.-`}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body3" width={130}>
              Pagás la primera cuota el {formatter(installmentFirstDueDate, 'date')}
            </Typography>
            <Typography component={'span'} variant="h1" color="primary.main">
              {(installmentCount || 0) > 1 ? `${installmentCount} cuotas` : `${installmentCount} cuota`}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="primary.main" width={130}>
              Valor de la cuota por mes
            </Typography>
            <Typography component={'span'} variant="h1" fontSize={'2rem'} color="primary.main">
              {`${formatter(installmentAmount.toString(), 'currency')}.-`}
            </Typography>
          </Stack>
          <Button
            variant="text"
            sx={{ alignItems: 'flex-end', gap: 1 }}
            onClick={() => router.push('/flow/REPAYMENT_TERMS')}
          >
            <Grid>Cambiar monto y plan de cuotas</Grid>
            <MaterialIcon icon="edit" color="primary" />
          </Button>
        </Stack>
      )}
      {bankAccountReference ? (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: COLORS.background[0],
            borderRadius: 2,
            gap: 2,
            p: 2,
          }}
        >
          <Stack>
            <Typography variant="body3">Te lo depositamos en</Typography>
            <Typography variant="h2">{`CBU ${bankAccountReference}`}</Typography>
          </Stack>
          <IconButton onClick={() => router.push('/flow/BANK_ACCOUNT_REFERENCE')}>
            <MaterialIcon icon="edit" color="primary" />
          </IconButton>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: COLORS.background[0],
          borderRadius: 2,
          gap: 2,
          p: 2,
        }}
      >
        <Stack>
          <Typography variant="body3">
            Pagás con {`${paymentMethod === 'DEBIT_CARD' ? 'débito automático' : ''}`}
          </Typography>
          <Typography variant="h2">{`${paymentMethod === 'CASH' ? 'Efectivo' : `${cardIssuer} ${cardNumber.slice(-8)}`}`}</Typography>
        </Stack>
        <IconButton onClick={() => router.push('/flow/PAYMENT_METHOD')}>
          <MaterialIcon icon="edit" color="primary" />
        </IconButton>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: COLORS.background[0],
          borderRadius: 2,
          gap: 2,
          p: 2,
        }}
      >
        {/* @todo: replace requestedAmount with correct values */}
        <Stack>
          <Typography variant="body3">TEA</Typography>
          <Typography variant="h2">{`${formatter(tea, 'number')}%`}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body3">TNA</Typography>
          <Typography variant="h2">{`${formatter(tna, 'number')}%`}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body3">CFTNA</Typography>
          <Typography variant="h2">{`${formatter(cftna, 'number')}%`}</Typography>
        </Stack>
      </Grid>
      <OnbForm<reviewAndSubmit> stepName={stepName!} submitLabel="Confirmar">
        <Checkbox
          label="Acepto los términos y condiciones"
          {...connectedField({
            checkbox: true,
            stepName,
            name: 'hasTermsAccepted',
            required: true,
            beforeChange: (value) => !!value,
            errorHandler: {
              type: 'checkbox',
              errorText: 'Acepta para los términos y condiciones para continuar',
            },
          })}
        />
      </OnbForm>
      <ConfirmationDetails
        termsAndConditions={termsAndConditions}
        requestedAmount={requestedAmount}
        installmentCount={installmentCount}
        paymentSchedule={paymentSchedule}
        documentNumber={documentNumber}
        gender={gender}
        fullName={fullName}
        birthDate={birthDate}
        taxpayerId={taxpayerId}
        email={email}
        mobilePhoneNumber={mobilePhoneNumber}
        province={province}
        paymentMethod={paymentMethod}
        bankAccountReference={bankAccountReference}
        installmentRates={stepData?.installmentRates}
      />
    </StepWrapper>
  );
}
