'use client';
import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Doodles } from '@repo/ui';
import { useRouter } from 'next/navigation';

/**
 * ErrorPage component renders a custom 404 error page.
 *
 * It includes a doodle illustration and error message
 *
 * @returns {JSX.Element} The rendered ErrorPage component.
 */

export const ErrorPage = () => {
  const router = useRouter();

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        gap: 4,
        width: '100%',
        paddingBottom: { xs: 4, md: 0 },
      }}
      data-testid="error-page"
    >
      <Stack alignItems="center" gap={4}>
        <Doodles type="end" />
        <Stack gap={2}>
          <Typography variant="h1">Ups! Ha habido un error</Typography>
        </Stack>
      </Stack>
      <Button variant="contained" onClick={() => router.refresh()}>
        Reintentar
      </Button>
    </Grid>
  );
};
