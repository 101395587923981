'use client';
import { OnbForm } from '@/components/form';
import { RootState } from '@/redux/store';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { identityVerificationStart } from '@repo/onb-api';
import { COLORS, Doodles, Ekyc, MaterialIcon, Metamap } from '@repo/ui';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function IdentityVerification({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname: identityVerificationStart['name'];
  stepdata?: identityVerificationStart['variables'];
}): JSX.Element {
  const [submitting, setSubmitting] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const instanceId = useSelector((state: RootState) => state.flowdata.id);

  useEffect(() => {
    setSubmitting(false);
  }, [stepData?.attempt]);

  return (
    <StepWrapper>
      {stepData?.flowType === 'SHORT_BIOMETRY' ? (
        <>
          {(stepData?.attempt || 0) > 0 ? (
            <>
              <Typography variant="h1">No pudimos validar tu identidad.</Typography>
              <Typography variant="h2">Por favor, intentá nuevamente</Typography>
              <Stack sx={{ py: 2 }} alignItems={'center'}>
                <Doodles type="end" />
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="h1" color={submitting ? COLORS.text.placeholder : undefined}>
                ¡Genial! Ahora vamos a validar tu identidad
              </Typography>
              <Typography variant="h2" color={submitting ? COLORS.text.placeholder : undefined}>
                Te vamos a pedir una selfie. Podés seguír estos tips:
              </Typography>
              <Stack alignItems={'center'} height={134} margin="0 auto" sx={{ aspectRatio: '1/1' }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: '100%', width: '100%', borderRadius: '50%', bgcolor: COLORS.background[1] }}
                >
                  <MaterialIcon icon="familiar_face_and_zone" color="primary" size={100} />
                </Box>
              </Stack>
            </>
          )}
          <Stack gap={3} sx={{ pb: 2 }}>
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon
                color={submitting ? 'disabled' : 'primary'}
                containerType="circle"
                icon="wb_sunny"
                sx={{ lineHeight: '1 !important' }}
              />
              <Typography variant="body2" color={submitting ? COLORS.text.placeholder : 'primary.main'}>
                Buscá un fondo claro y bien iluminado
              </Typography>
            </Grid>
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon
                color={submitting ? 'disabled' : 'primary'}
                containerType="circle"
                icon="visibility"
                sx={{ lineHeight: '1 !important' }}
              />
              <Typography variant="body2" color={submitting ? COLORS.text.placeholder : 'primary.main'}>
                Poné el celular a la altura de tus ojos
              </Typography>
            </Grid>
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon
                color={submitting ? 'disabled' : 'primary'}
                containerType="circle"
                icon="sentiment_very_satisfied"
                sx={{ lineHeight: '1 !important' }}
              />
              <Typography variant="body2" color={submitting ? COLORS.text.placeholder : 'primary.main'}>
                Evitá tener objetos que te tapen la cara (como lentes o tapabocas)
              </Typography>
            </Grid>
          </Stack>
        </>
      ) : (
        <>
          {(stepData?.attempt || 0) > 0 ? (
            <>
              <Typography variant="h1">No pudimos validar tu identidad.</Typography>
              <Typography variant="h2">Por favor, intentá nuevamente</Typography>
              <Stack sx={{ py: 2 }} alignItems={'center'}>
                <Doodles type="end" />
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="h1" color={submitting ? COLORS.text.placeholder : 'primary.main'}>
                ¡Genial! Ahora vamos a validar tu identidad
              </Typography>
              <Typography variant="h2" color={submitting ? COLORS.text.placeholder : 'primary.main'}>
                Buscá tu DNI y preparate para una selfie. Podés seguír estos tips:
              </Typography>
              <Stack sx={{ py: 1 }}>
                <Ekyc />
              </Stack>
            </>
          )}
          <Stack gap={3} sx={{ pb: 2 }}>
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon
                color={submitting ? 'disabled' : 'primary'}
                containerType="circle"
                icon="ar_on_you"
                sx={{ lineHeight: '1 !important' }}
              />
              <Typography variant="body2" color={submitting ? COLORS.text.placeholder : 'primary.main'}>
                Buscá un lugar con luz, con fondo claro y poné el celular a la altura de tus ojos.
              </Typography>
            </Grid>
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon
                color={submitting ? 'disabled' : 'primary'}
                containerType="circle"
                icon="id_card"
                sx={{ lineHeight: '1 !important' }}
              />
              <Typography variant="body2" color={'primary.main'}>
                Evitá el uso del flash y girá el celular para lograr una imagen más nítida.
              </Typography>
            </Grid>
          </Stack>
        </>
      )}
      <OnbForm<identityVerificationStart> stepName={stepName} hideSubmit sx={{ justifyContent: 'flex-end' }}>
        <Stack>
          <button ref={submitRef} style={{ display: 'none' }}></button>
          <Metamap
            submitLabel={(stepData?.attempt || 0) > 0 ? 'Reintentar' : 'Comenzar'}
            submitting={submitting}
            clientId={stepData?.clientId}
            flowId={stepData?.flowId}
            identityId={stepData?.identityId}
            traceId={stepData?.traceId}
            instanceId={instanceId}
            callback={() => {
              setSubmitting(true);
              submitRef.current?.click();
            }}
          />
        </Stack>
      </OnbForm>
    </StepWrapper>
  );
}
