'use client';

import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material';
import { apiResponse } from '@repo/api';
import { type activateParams } from '@repo/customer-api';
import { useState } from 'react';
import { MaterialIcon } from '../../components';

type ActivateProps<T> = {
  hash: string;
  username: string;
  connector?: (_data: activateParams) => Promise<apiResponse<T>>;
  callback?: (_connectorResponse: T) => void;
  onError?: () => void;
};

/**
 * A reusable activation kickstart component that dispatches user activation flow.
 *
 * @param {Object} connector - A function that connects to an API to authenticate the user.
 * @param {string} defaultValue - The default value for the activation form @default ''.
 * @param {Function} callback - An optional callback function to handle the API response.
 * @param {credentialType} credentialType - The type of credential to use in the login form @default email.
 * @param {Function} onError - An optional callback function to handle the API response.
 * @return {JSX.Element} A JSX element representing the login form.
 */
export const Activate = <T,>({ connector, callback, hash, username, onError }: ActivateProps<T>) => {
  const [showFieldErrors, setShowFieldErrors] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    connector?.({
      username,
      changeHash: hash,
      password: data.get('password') as string,
    }).then((connectorResponse) => {
      if (connectorResponse.softError) {
        setLoading(false);
        onError?.();
      } else {
        callback?.(connectorResponse);
      }
    });
  };
  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit}
      data-testid="login-form"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
    >
      <Stack gap={2}>
        <Typography variant="h1">Activar cuenta</Typography>
        <Typography variant="h2">Escriba una nueva contraseña para activar su cuenta</Typography>
        <TextField
          onFocus={() => setShowFieldErrors(false)}
          onChange={(event) => setPassword(event.target.value)}
          onBlur={() =>
            setShowFieldErrors(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\#?.!@$%^&\*-]).{8,}$/.test(password))
          }
          label="Nueva contraseña"
          name="password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Nueva contraseña"
          slotProps={{
            input: {
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)} data-testid="show-password">
                  {showPassword ? <MaterialIcon icon="visibility_off" /> : <MaterialIcon icon="visibility" />}
                </IconButton>
              ),
            },
            htmlInput: { autoComplete: 'current-password', 'data-testid': 'password' },
          }}
          error={showFieldErrors}
          helperText={
            showFieldErrors
              ? 'La contraseña debe contener una mayúscula, una minúscula, un número y un caracter especial'
              : ''
          }
        />
      </Stack>
      <Button type="submit" disabled={loading} fullWidth variant="contained" data-testid="login">
        {loading ? (
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} />
            Cargando...
          </Box>
        ) : (
          <span>Activar</span>
        )}
      </Button>
    </Box>
  );
};
