'use client';

import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material';
import { apiResponse } from '@repo/api';
import { formatter } from '@repo/utils';
import { useRef, useState } from 'react';
import { MaterialIcon } from '../../components';

type LoginProps<T> = {
  credentialType?: 'username' | 'documentNumber';
  connector?: (_data: { username: string; password: string }) => Promise<apiResponse<T>>;
  callback?: (_connectorResponse: T) => void;
  onError?: () => void;
  forgotPassword?: () => void;
  extraButtons?: {
    label: string;
    onClick: () => void;
  }[];
};

/**
 * A reusable login component that handles user authentication.
 * It also features validation for all fields and a loading state.
 *
 * @param {Object} connector - A function that connects to an API to authenticate the user.
 * @param {Function} callback - An optional callback function to handle the API response.
 * @param {'email' | 'documentNumber'} credentialType - The type of credential to use in the login form @default email.
 * @param {Object} extraButtons - An optional array of extra buttons to display in the login form.
 * @return {JSX.Element} A JSX element representing the login form.
 */
export const Login = <T,>({
  connector,
  callback,
  credentialType = 'documentNumber',
  extraButtons,
  forgotPassword,
  onError,
}: LoginProps<T>) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<{ username?: string; password?: string }>({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  // Validation functions for different fields
  const validateDocument = (doc: string) => /^\d{7,10}$/.test(doc) || doc === '';

  // Function that validates each field on blur
  const handleBlur = () => {
    if (credentialType === 'documentNumber' && !validateDocument(username.replace(/\D/g, ''))) {
      setFormErrors((prevErrors) => ({ ...prevErrors, ['username']: 'Ingresá un DNI válido' }));
    }
    if (credentialType !== 'documentNumber' && username !== '') {
      setFormErrors((prevErrors) => ({ ...prevErrors, ['username']: '' }));
    }
    if (password !== '') {
      setFormErrors((prevErrors) => ({ ...prevErrors, ['password']: '' }));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    usernameRef.current?.blur();
    passwordRef.current?.blur();
    if (!username) {
      setFormErrors((prevErrors) => ({ ...prevErrors, ['username']: 'Este campo es requerido' }));
    }
    if (!password) {
      setFormErrors((prevErrors) => ({ ...prevErrors, ['password']: 'Este campo es requerido' }));
    }
    if (Object.entries(formErrors).some((content) => content[1] !== '') || loading || !username || !password) {
      return false;
    }
    setLoading(true);

    connector?.({
      username: username,
      password: password,
    })
      .then((connectorResponse) => {
        if (connectorResponse.hardError) {
          setLoading(false);
          onError?.();
        } else if (connectorResponse.softError) {
          setLoading(false);
          setFormErrors({
            username: 'Usuario y/o contraseña incorrectos',
            password: 'Usuario y/o contraseña incorrectos',
          });
        } else {
          callback?.(connectorResponse);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        onError?.();
      });
  };

  const resetErrors = () => setFormErrors({ username: '', password: '' });

  return (
    <Box component="form" onSubmit={handleSubmit} data-testid="login-form">
      <Stack gap={2}>
        <Typography variant="h1">Iniciar sesión</Typography>

        {credentialType === 'username' ? (
          <TextField
            ref={usernameRef}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            label="Nombre de usuario"
            autoComplete="username"
            name="username"
            autoFocus
            placeholder="Ej: juan.perez"
            onBlur={handleBlur}
            onFocus={resetErrors}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{ htmlInput: { inputMode: 'string', autoComplete: 'username', 'data-testid': 'username' } }}
          />
        ) : (
          <TextField
            ref={usernameRef}
            onChange={(e) => setUsername(e.target.value.replace(/\D/g, ''))}
            value={formatter(username, 'dni')}
            label="DNI"
            autoComplete="username"
            name="username"
            autoFocus
            placeholder="Ej: 12345678"
            onBlur={handleBlur}
            onFocus={resetErrors}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{ htmlInput: { maxLength: 10, inputMode: 'numeric', 'data-testid': 'documentNumber' } }}
          />
        )}

        <TextField
          ref={passwordRef}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          label="Contraseña"
          name="password"
          autoComplete="password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Contraseña"
          onBlur={handleBlur}
          onFocus={resetErrors}
          error={!!formErrors.password}
          helperText={formErrors.password}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)} data-testid="show-password">
                  {showPassword ? <MaterialIcon icon="visibility_off" /> : <MaterialIcon icon="visibility" />}
                </IconButton>
              ),
            },
            htmlInput: { autoComplete: 'current-password', 'data-testid': 'password' },
          }}
        />

        {forgotPassword && (
          <Typography variant="body2" color="primary.main" sx={{ cursor: 'pointer' }} onClick={forgotPassword}>
            Olvidé mi contraseña
          </Typography>
        )}

        {extraButtons?.map((button) => (
          <Button
            type="button"
            key={button.label}
            onClick={(e) => {
              e.preventDefault();
              button.onClick();
            }}
            fullWidth
            variant="inverted"
          >
            {button.label}
          </Button>
        ))}

        <Button type="submit" disabled={loading} fullWidth variant="contained" data-testid="login" sx={{ mt: 2 }}>
          {loading ? (
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size={20} />
              Cargando...
            </Box>
          ) : (
            <span>Ingresar</span>
          )}
        </Button>
      </Stack>
    </Box>
  );
};
