import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Doodles } from '@repo/ui';
import Link from 'next/link';

/**
 * NotFoundPage component renders a custom 404 error page.
 *
 * It includes a doodle illustration, error message, and a button to
 * navigate back to the homepage.
 *
 * @returns {JSX.Element} The rendered NotFoundPage component.
 */

export const NotFoundPage = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        gap: 4,
        height: '100%',
        width: '100%',
        paddingBottom: { xs: 4, md: 0 },
      }}
      data-testid="not-found-page"
    >
      <Stack alignItems="center" gap={4}>
        <Doodles type="end" />
        <Stack gap={2}>
          <Typography variant="h1">Ups! Ha habido un error</Typography>
          <Typography variant="body1" color="secondary.main">
            La página no existe
          </Typography>
        </Stack>
      </Stack>
      <Link href="/" style={{ width: '100%' }}>
        <Button fullWidth variant="contained">
          Volver al inicio
        </Button>
      </Link>
    </Grid>
  );
};
