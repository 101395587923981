'use client';
import { hideModal } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Button, Stack } from '@mui/material';
import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { Doodles } from '@repo/ui';
import { deleteClientCookie, getClientCookie, parseJwt } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Renders a back to store body for a Modal component with redirect logic.
 *
 * @return {JSX.Element} The rendered ModalCancelBody component.
 */
export const BackToStoreBody = (): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { currentStep } = useSelector((state: RootState) => state.navigationdata);
  return (
    <>
      <Doodles type="confirmation" />
      <Stack gap={2} width="100%" mt={2}>
        <Button
          onClick={() => {
            if (currentStep) {
              deleteClientCookie('internal');
              deleteClientCookie('returnURLError');
              deleteClientCookie('returnURLSuccess');
              deleteClientCookie('orderToken');
              deleteClientCookie('instanceId');
              const decodedJwt = parseJwt(getClientCookie('tokenData') || '') as getOrderCredentialJWT;
              router.push(decodedJwt.returnURLError || '/');
              deleteClientCookie('tokenData');
            } else {
              router.push(getClientCookie('returnURLInitialize') || '/');
            }
          }}
        >
          Volver a la tienda
        </Button>
        <Button variant="inverted" onClick={() => dispatch(hideModal())}>
          Cancelar
        </Button>
      </Stack>
    </>
  );
};
