'use client';
import Loading from '@/app/(withLayout)/loading';
import { OnbForm } from '@/components/form';
import { sendOnbEvent } from '@/components/newRelic';
import { connectedField } from '@/redux/fields';
import { pushStepVariables } from '@/redux/flowSlice';
import { showToast } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Box, Paper, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { customerRegistration, getInstallmentData, repaymentTerms } from '@repo/onb-api';
import { COLORS, Slider } from '@repo/ui';
import { formatter } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function RepaymentTerms({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname: repaymentTerms['name'];
  stepdata?: repaymentTerms['variables'];
}): JSX.Element {
  const router = useRouter();
  const dispatch = useDispatch();
  const { id, variables = {} } = useSelector((state: RootState) => state.flowdata);
  const { firstName, policyMinimumAmount, policyMaximumAmount, requestedAmount } = variables;
  const [amount, setAmount] = useState(requestedAmount || policyMaximumAmount);
  const [amountError, setAmountError] = useState(false);
  const [rawValue, setRawValue] = useState(
    stepData?.installments?.find(({ planId }) => planId === stepData?.installmentPlanId)?.count || 0,
  );
  const [finalValue, setFinalValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const formAreaRef = useRef(null);

  useEffect(() => {
    if (stepData?.installments?.length === 0) {
      sendOnbEvent({ type: 'error', subtype: 'noInstallments', data: { stepName }, severity: 'high' });
      router.push('/error');
    }
  }, [stepData?.installments, stepName, router]);

  useEffect(() => {
    setFinalValue(stepData?.installments?.find(({ count }) => count === rawValue)?.amount || 0);
  }, [stepData?.installments, rawValue]);

  const defaultInstallmentValue = stepData?.installmentPlanId
    ? stepData?.installments?.find(({ planId }) => planId === stepData?.installmentPlanId)?.planId
    : [...(stepData?.installments || [])].sort((a, b) => b.count - a.count)[0]?.planId;

  const handleAmountChange = async (value: number) => {
    setAmountError(false);
    setIsLoading(true);
    setAmount(value);
    if ((policyMinimumAmount && value < policyMinimumAmount) || (policyMaximumAmount && value > policyMaximumAmount)) {
      setAmountError(true);
      return;
    }
    try {
      const installmentData = await getInstallmentData({
        instanceId: id,
        name: stepName,
        amount: value,
      });
      //@todo - not handling errors
      if (stepName && installmentData?.variables?.installments)
        dispatch(
          pushStepVariables({ stepName, variable: 'installments', value: installmentData.variables.installments }),
        );
    } catch (e) {
      dispatch(showToast({ text: 'Se produjo un error', kind: 'error' }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (formAreaRef.current) {
      (formAreaRef.current as HTMLDivElement).addEventListener('touchstart', (e) => {
        for (const touch of e.touches) {
          if (touch.clientX > 20 && touch.clientX < window.innerWidth - 20) return;
        }
        // prevent swipe navigation gesture
        e.preventDefault();
      });
    }
  }, [formAreaRef]);
  return (stepData?.installments?.length || 0) === 0 ? (
    <Loading />
  ) : (
    <StepWrapper>
      <Typography variant="h1">
        ¡Buenas noticias{firstName ? ` ${formatter(firstName, 'capitalize')}!` : '!'}
      </Typography>
      <Typography variant="h2">Esta es tu oferta:</Typography>
      <Grid ref={formAreaRef} sx={{ height: '100%' }}>
        <OnbForm<customerRegistration> stepName={stepName!} disabled={isLoading}>
          <Box display="flex" justifyContent="space-between">
            <TextField
              label="Monto que estás solicitando"
              data-testid="requestedAmount"
              sx={{
                '.MuiInputBase-input': {
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  color: COLORS.primary.purple.main,
                  textAlign: 'right',
                },
              }}
              {...connectedField({
                onChange: (e: any) => {
                  //@todo - narrow down types
                  let numericValue = Number(e.target.value.replace(/[^0-9]/g, ''));
                  handleAmountChange(numericValue);
                },
                stepName,
                name: 'requestedAmount',
                defaultValue: policyMaximumAmount,
                beforeChange: (value) => (typeof value === 'string' ? Number(value?.replace(/[^0-9]/g, '')) : value),
              })}
              error={amountError}
              helperText={
                amountError
                  ? `El monto solicitado debe estar entre ${formatter(policyMinimumAmount, 'currency')} y ${formatter(policyMaximumAmount, 'currency')}`
                  : ''
              }
              value={formatter(amount?.toString(), 'currency')}
            />
          </Box>
          <Grid
            sx={{
              filter: isLoading ? 'grayscale(100%)' : 'none',
              pointerEvents: isLoading ? 'none' : 'auto',
              transition: 'filter 0.3s ease',
            }}
          >
            <Slider
              data-testid="installmentPlanId"
              label={`Pagás la primera cuota el ${formatter(stepData?.installments?.find(({ count }) => count === rawValue)?.firstDueDate || '', 'date')}`}
              valueLabelDisplay="off"
              min={stepData?.installments?.reduce((acc, { count }) => Math.min(acc, count), Infinity) || 0}
              max={stepData?.installments?.reduce((acc, { count }) => Math.max(acc, count), 0) || 0}
              marks={stepData?.installments?.map(({ count }) => ({ value: count }))?.sort((a, b) => a.value - b.value)}
              step={null}
              labelWrapper={(label) => `${label} cuotas`}
              onRawChange={(v) => setRawValue(v as number)}
              {...connectedField({
                defaultValue: defaultInstallmentValue,
                stepName,
                name: 'installmentPlanId',
                beforeRender: (value) =>
                  stepData?.installments?.find(({ planId }) => planId === parseInt(value))?.count,
                beforeChange: (value) =>
                  stepData?.installments?.find(({ count }) => count === parseInt(value))?.planId ||
                  defaultInstallmentValue,
              })}
            />
            <Paper
              elevation={0}
              sx={{
                backgroundColor: COLORS.background[1],
                p: 2,
                mt: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h2" color="primary.main" sx={{ textWrap: 'wrap', maxWidth: '120px' }}>
                Valor de la cuota por mes
              </Typography>
              <Typography variant="h1" color="primary.main">
                {formatter(finalValue.toString(), 'currency')}
              </Typography>
            </Paper>
          </Grid>
        </OnbForm>
      </Grid>
    </StepWrapper>
  );
}
