/* eslint-disable no-case-declarations */
import { areaCodes } from '../areaCode';

export const formatter = (
  value: string | number | undefined,
  type: 'dni' | 'currency' | 'capitalize' | 'date' | 'number' | 'phone',
) => {
  if (!value && value !== 0) return '';
  const stringValue = `${value}`;
  switch (type) {
    case 'capitalize':
      return (
        stringValue
          ?.replaceAll('_', ' ')
          .split(' ')
          .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
          .join(' ') || ''
      );
    case 'currency':
      return stringValue
        ? new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }).format(
            Number(stringValue),
          )
        : '';
    case 'number':
      return stringValue
        ? new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2 }).format(Number(stringValue.replace(',', '.')))
        : '';
    case 'dni':
      return stringValue ? Number(stringValue).toLocaleString().replaceAll(',', '.') : '';
    case 'date':
      return stringValue
        ? new Date(stringValue).toLocaleDateString('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })
        : '';
    case 'phone':
      const areaCode = areaCodes.find((code) => code === stringValue.slice(0, code.length));
      const areaCodeLength = areaCode?.length || 0;
      return areaCode
        ? `(${areaCode}) ${stringValue.slice(areaCodeLength, areaCodeLength + 4)} ${stringValue.slice(areaCodeLength + 4)}`
        : stringValue;
  }
};
