'use client';
import { Button, FormHelperText, Input, InputProps, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Renders Otp inputs with helperText and resend password button.
 *
 * @param {string} fields - The number of inputs to render.
 * @param {Function} resend - The function for the resend button.
 * @return {JSX.Element} The rendered custom checkbox component.
 */
export const Otp = ({
  fields,
  resend,
  helperText,
  ...rest
}: {
  fields: number;
  resend: () => void;
  helperText?: string;
} & InputProps): JSX.Element => {
  const { onChange, ...textFieldProps } = rest;
  const value = textFieldProps.value as string;
  const otpRef = useRef<HTMLInputElement[] | null[]>([null]);
  const submitRef = useRef<HTMLButtonElement>(null);
  const [rawValue, setRawValue] = useState(value || '');
  const [focus, setFocus] = useState(false);
  const [smsTimeout, setSmsTimeout] = useState(0);
  const hiddenField = useRef<HTMLInputElement | null>(null);
  const smsInterval = useRef(0);

  useEffect(() => {
    onChange?.({ target: { value: rawValue } } as React.ChangeEvent<HTMLInputElement>);
    if (submitRef?.current && rawValue.length === fields) {
      setTimeout(() => {
        submitRef.current?.click();
      }, 100);
    }
  }, [rawValue]);

  useEffect(() => {
    smsInterval.current = window.setTimeout(() => {
      setSmsTimeout((t) => t - 1);
    }, 1000);
    if (smsTimeout <= 0) clearTimeout(smsInterval.current);
  }, [smsTimeout]);

  return (
    <Stack gap={1}>
      <button type="submit" ref={submitRef} style={{ display: 'none' }} />
      <Input
        {...textFieldProps}
        sx={{ height: 0, width: 0, opacity: 0 }}
        ref={hiddenField}
        value={rawValue}
        autoComplete="one-time-code"
        inputProps={{ maxLength: fields, inputMode: 'numeric', 'data-testid': 'hidden-field' }}
        onChange={(e) => {
          onChange?.(e);
          setRawValue(e.target.value?.replaceAll(/\D+/g, ''));
        }}
        required={textFieldProps.required}
        autoFocus
        onFocus={() => setFocus(true)}
        onBlur={(e) => {
          textFieldProps.onBlur?.(e);
          setFocus(false);
        }}
      />
      <Grid
        onClick={() => {
          hiddenField.current?.click();
        }}
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}
      >
        {[...Array(fields)].map((_, index) => (
          <TextField
            data-focus={focus && (index === rawValue.length || (index === fields - 1 && index === rawValue.length - 1))}
            key={index}
            slotProps={{
              htmlInput: {
                tabIndex: -1,
                inhert: 'true',
                inputMode: 'numeric',
                maxLength: 1,
                style: { fontSize: 38, textAlign: 'center' },
              },
              input: {
                sx: {
                  pointerEvents: 'none',
                  width: { xs: '60px', sm: '64px' },
                  height: { xs: '60px', sm: '64px' },
                },
              },
            }}
            data-testid={`otp-${index + 1}`}
            inputRef={(el) => (otpRef.current[index] = el)}
            sx={{ width: 'min-content', '& p': { display: 'none' } }}
            // onBlur={() => {}}
            error={textFieldProps.error}
            value={rawValue?.[index] || ''}
          />
        ))}
      </Grid>
      <FormHelperText sx={{ alignSelf: 'center', py: 1 }} error>
        {textFieldProps.error && helperText}
      </FormHelperText>
      <Button
        disabled={smsTimeout > 0}
        variant="text"
        onClick={(e) => {
          e.preventDefault();
          setRawValue('');
          otpRef.current?.[0]?.click();
          setSmsTimeout(45);
          resend?.();
        }}
        sx={{ width: 'fit-content', alignSelf: 'center' }}
      >
        Reenviar código
      </Button>
      <FormHelperText sx={{ alignSelf: 'center', py: 1, display: smsTimeout > 0 ? 'block' : 'none' }}>
        Código enviado. Para reenviar otro por favor espere 00:{`${smsTimeout}`.padStart(2, '0')}
      </FormHelperText>
    </Stack>
  );
};
