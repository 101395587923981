'use client';
import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { success } from '@repo/onb-api';
import { COLORS, Doodles, MaterialIcon } from '@repo/ui';
import Link from 'next/link';
import ReactConfetti from 'react-confetti';

export default function Success({ stepdata: stepData }: { stepdata?: success['variables'] }) {
  const { firstName } = stepData || {};
  return (
    <Grid
      sx={{
        border: '2px solid #fff',
        borderRadius: { xs: 0, md: '24px' },
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        height: '100%',
        width: '100%',
        paddingBottom: { xs: 4, md: 0 },
      }}
    >
      <Stack sx={{ width: '100%', flex: 1 }}>
        <Stack alignItems="center" gap={4} bgcolor={COLORS.background[0]} sx={{ flex: 1, justifyContent: 'center' }}>
          <Stack gap={2} alignItems={'center'} sx={{ pt: 4, pb: 8, px: { xs: 2, md: 8 } }}>
            <Doodles type="success" />
            <Typography variant="h1" sx={{ pt: 2 }}>
              {/* ¡Felicitaciones {formatter(firstName, 'capitalize')}! */}
              ¡Ahora nos toca trabajar a nosotros!
            </Typography>
            <Typography color="secondary">
              {/* En las próximas horas, recibirás la acreditación del dinero en tu cuenta. */}
              Estamos revisando tus datos. Pronto te contactaremos para que empieces a disfrutar tu préstamo.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          gap={1}
          sx={{
            borderRadius: 6,
            mt: -4,
            bgcolor: 'background.paper',
            color: 'primary.main',
            pt: { xs: 4, md: 4 },
            pb: 0,
            px: { xs: 2, md: 8 },
          }}
        >
          <Typography variant="h1" color="primary" sx={{ mb: 2, fontSize: '1.2rem' }}>
            Accedé a tu Portal de Cliente
          </Typography>
          <Stack gap={1}>
            <Grid container gap={1} alignItems={'center'} flexWrap={'nowrap'}>
              <MaterialIcon icon="check" />
              <Typography variant="body2" fontWeight={300} color="primary">
                Consultá el estado de tu préstamo.
              </Typography>
            </Grid>
            <Grid container gap={1} alignItems={'center'} flexWrap={'nowrap'}>
              <MaterialIcon icon="check" />
              <Typography variant="body2" fontWeight={300} color="primary">
                Mirá las fechas de vencimiento.
              </Typography>
            </Grid>
            <Grid container gap={1} alignItems={'center'} flexWrap={'nowrap'}>
              <MaterialIcon icon="check" />
              <Typography variant="body2" fontWeight={300} color="primary">
                Descargá tu cuponera de pago.
              </Typography>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ width: '100%', p: { xs: 2, md: 8 }, pt: { xs: 4, md: 4 } }}>
        <Link href={process.env.NEXT_PUBLIC_CUSTOMER_ROUTE || ''}>
          <Button fullWidth variant="contained">
            Ingresar al Portal de Cliente
          </Button>
        </Link>
      </Box>
      <ReactConfetti recycle={false} numberOfPieces={500} />
    </Grid>
  );
}
